import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
    title: 'Bugless | Node.JS ', // e.g: 'Name | Developer'
    lang: 'en', // e.g: en, es, fr, jp
    description: 'A personal portfolio site for my Node.JS work.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
    title: '',
    name: '',
    subtitle: '',
    cta: '',
};

// ABOUT DATA
export const aboutData = {
    img: 'profile.jpg',
    paragraphOne: '',
    paragraphTwo: '',
    paragraphThree: '',
    resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
    {
        id: nanoid(),
        img: 'giveaways.jpg',
        title: 'Giveaways & Drops',
        info: 'A generator bot, with automated drops and a generator using bettersqlite3.',
        info2: '',
        url: 'https://discordapp.com/oauth2/authorize?client_id=715370284055789585&scope=bot&permissions=8',
        repo: '', // if no repo, the button will not show up
    },
    {
        id: nanoid(),
        img: 'mirroring.jpg',
        title: 'Message Mirroring Bot',
        info: 'A bot that mirrors messages across servers, Example: Server 1 has Bugless send "Hello" the bot would copy the message to Server 2 as Bugless saying "Hello"',
        info2: '',
        url: '',
        repo: '', // if no repo, the button will not show up
    },
    {
        id: nanoid(),
        img: 'moderation.jpg',
        title: 'Moderation Bot',
        info: 'A basic moderation bot with ban, kick, warn, announcements, etc.',
        info2: '',
        url: '',
        repo: '', // if no repo, the button will not show up
    },
    {
        id: nanoid(),
        img: 'ticket.jpg',
        title: 'Ticket Bot',
        info: 'A simple ticket bot with commands like new, close, commission, etc.',
        info2: '',
        url: '',
        repo: '', // if no repo, the button will not show up
    }
];

// FOOTER DATA
export const footerData = {
    networks: [
        {
            id: nanoid(),
            name: 'discord.png',
            url: 'https://i.imgur.com/gZjwWKb.png',
        },
        {
            id: nanoid(),
            name: 'glitch.png',
            url: 'https://glitch.com/@Bugless',
        },
        {
            id: nanoid(),
            name: 'github.png',
            url: 'https://github.com/RealBugless/',
        },
    ],
};

// Github start/fork buttons
export const githubButtons = {
    isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
